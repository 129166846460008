/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./InplayMatchBets.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { dateTimeFormatter, convertToUTC } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEye, faEdit } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Select from "react-select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import useGetUserOnlySession from "../../hooks/Games/Inplay/useGetUserOnlySession";
import useDeleteBets from "../../hooks/Games/Inplay/useDeleteBets";
import useDeleteSelectedSession from "../../hooks/Games/Inplay/useDeleteSelectedSession";
import useSessionHideView from "../../hooks/Games/Inplay/useSessionHideView";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useDeleteSessionBetsBySession from "../../hooks/Games/Inplay/useDeleteSessionBetsBySession";
// import useUpdateSessionBet from "../../hooks/Games/useUpdateSessionBet";

export default function InplaySessionBets() {
  const location = useLocation();
  const navigate = useNavigate();

  const userPassword = localStorage.getItem("password");

  const uuid = localStorage.getItem("UUID");
  const userCode = localStorage.getItem("CODE");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  const accountType = localStorage.getItem("account");

  const UrlSearchParams = new URLSearchParams(location.search);
  const matchId = UrlSearchParams.get("id");

  const { mutate: getUserOnlySession } = useGetUserOnlySession();
  const { mutate: deleteBets } = useDeleteBets();
  const { mutate: deleteSelectedSession } = useDeleteSelectedSession();
  const { mutate: sessionHideView } = useSessionHideView();
  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: deleteSessionBetsBySession } =
    useDeleteSessionBetsBySession();
  // const { mutate: updateSessionBet } = useUpdateSessionBet();

  const [users, setUsers] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [onDate, setOnDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [activeFilter, setActiveFilter] = useState("user");

  const handleGetSessionOfMatch = () => {
    const payload = {
      match_id: matchId,
      logged_acc_type: accountType,
      user_id: uuid,
    };

    getUserOnlySession(payload, {
      onSuccess: (response) => {
        //console.log(response);

        let filteredData = [];

        if (accountType !== "admin") {
          response.data.filter((item) => {
            if (item.view === true || item.view === 1) {
              if (item.trash === false || item.trash === null) {
                filteredData.push(item);
              }
            }
          });
        } else {
          if (isMasterAdmin === "1") {
            filteredData = response.data;
          } else {
            filteredData = response.data.filter(
              (item) => item.view === true || item.view === 1
            );
          }
        }

        setSessionData(filteredData);
        setAllData(filteredData);

        let userData = [];
        let sessionsData = [];

        filteredData.forEach((el) => {
          if (!userData.some((ele) => ele.id === el.user_account.id)) {
            userData.push({
              id: el.user_account.id,
              name: `${el.user_account.name}`,
              code: `${el.user_account.code}`,
            });
          }
          if (!sessionsData.some((ele) => ele.name === el.session)) {
            sessionsData.push({ name: `${el.session}` });
          }
        });

        setUsers(userData);
        setSessions(sessionsData);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(handleGetSessionOfMatch, 9000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeUser(value) {
    if (value === "all" || value === "") {
      setSessionData(allData);
    } else {
      const filteredData = allData.filter(
        (item) => item.user_account.id === value
      );
      setSessionData(filteredData);
    }
  }

  function changeSession(value) {
    if (value === "All" || value === "") {
      setSessionData(allData);
    } else {
      const filteredData = allData.filter((item) => item.session === value);
      setSessionData(filteredData);
    }
  }

  function handleDeleteSessions(e) {
    e.preventDefault();
    const form = e.target;
    let fromDate = String(onDate + " " + fromTime);
    let toDate = String(onDate + " " + toTime);

    fromDate = convertToUTC(fromDate);
    toDate = convertToUTC(toDate);

    const payload = {
      user_id: selectedUser,
      from_date: fromDate,
      to_date: toDate,
      type: "session",
      match_id: matchId,
      deletedBy: userCode,
    };

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete all bets from ${fromDate} to ${toDate}. You won't be able to revert it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@9ex") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBets(payload, {
          onSuccess: (response) => {
            console.log(response);
            toast.success("All bets deleted successfully");
            form.reset();
            setSelectedUser("");
            handleGetSessionOfMatch();
          },
          onError: (error) => {
            console.log(error);
            toast.error(error.message);
          },
        });
      }
    });
  }

  function handleDeleteSessionsBySession(e) {
    e.preventDefault();
    const form = e.target;
    let fromDate = String(onDate + " " + fromTime);
    let toDate = String(onDate + " " + toTime);

    fromDate = convertToUTC(fromDate);
    toDate = convertToUTC(toDate);

    const payload = {
      session: selectedSession,
      from_date: fromDate,
      to_date: toDate,
      match_id: matchId,
      deletedBy: userCode,
    };

    console.log(payload);

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete all bets from ${fromDate} to ${toDate}. You won't be able to revert it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@9ex") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSessionBetsBySession(payload, {
          onSuccess: (response) => {
            console.log(response);
            toast.success("All bets deleted successfully");
            form.reset();
            setSelectedUser("");
            setSelectedSession("");
            handleGetSessionOfMatch();
          },
          onError: (error) => {
            console.log(error);
            toast.error(error.message);
          },
        });
      }
    });
  }

  function deleteSession(sessionId) {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this Bet. You won't be able to revert it.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@9ex") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          session_id: sessionId,
          deletedBy: userCode,
        };

        deleteSelectedSession(payload, {
          onSuccess: () => {
            toast.success("Session deleted successfully");
            handleGetSessionOfMatch();
          },
          onError: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  // function trashSession(session) {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You want to delete this Bet. You won't be able to revert it.`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //     input: "text",
  //     inputLabel: "Enter your password",
  //     inputValidator: (value) => {
  //       if (!value) {
  //         return "Password is required!";
  //       }
  //       if (value !== "Tihor@9ex") {
  //         return "Invalid Password!";
  //       }
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const payload = {
  //         id: session.id,
  //         user_id: session.user_id,
  //         session: session.session,
  //         match_id: session.match_id,
  //         trash: true,
  //         deletedBy: userCode,
  //       };

  //       updateSessionBet(payload, {
  //         onSuccess: () => {
  //           toast.success("Session deleted successfully");
  //           handleGetSessionOfMatch();
  //         },
  //         onError: (error) => {
  //           console.log(error);
  //         },
  //       });
  //     }
  //   });
  // }

  function handleSessionView(id, status) {
    const sessionId = id;
    const payload = {
      view: !status,
    };

    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${status ? "hide" : "unhide"} this sessions.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${status ? "hide" : "unhide"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        sessionHideView(
          { sessionId, payload },
          {
            onSuccess: () => {
              toast.success("Session updated successfully");
              handleGetSessionOfMatch();
            },
            onError: (error) => {
              console.log(error);
            },
          }
        );
      }
    });
  }

  useEffect(() => {
    if (matchId === undefined && matchId === null) {
      navigate("/inplay");
    } else {
      handleGetSessionOfMatch();

      const payload = {
        user_id: uuid,
        acc_type: accountType,
      };

      accountStatus(payload, {
        onSuccess: (response) => {
          let userData = response.data.data;

          if (
            userData.status === false ||
            userData.status === "false" ||
            userData.status === "0" ||
            userData.status === 0
          ) {
            localStorage.clear();
            sessionStorage.clear();
          } else if (userData.password !== userPassword) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Session Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Session Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            {accountType === "admin" ? (
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mb-3">
                    <button
                      className={`btn me-2 ${
                        activeFilter === "user"
                          ? "btn-primary"
                          : "btn-secondary"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveFilter("user");
                      }}
                    >
                      User Filter
                    </button>
                    <button
                      className={`btn ${
                        activeFilter === "session"
                          ? "btn-primary"
                          : "btn-secondary"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveFilter("session");
                      }}
                    >
                      Session Filter
                    </button>
                  </div>
                  {activeFilter === "user" ? (
                    <form onSubmit={handleDeleteSessions}>
                      <div className="row">
                        <div className="form-group col-md-3">
                          <label className="form-label">Users</label>
                          <Select
                            options={[
                              { value: "", label: "Select" },
                              { value: "all", label: "All" },

                              ...users.map((user) => ({
                                value: user.id,
                                label: `${user.code} - ${user.name}`,
                              })),
                            ]}
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={""}
                            isDisabled={false}
                            isLoading={users.length === 0 ? true : false}
                            isSearchable={true}
                            isRtl={false}
                            name="selectedUser"
                            onChange={(e) => {
                              const selected = e?.value;
                              console.log(selected);
                              setSelectedUser(selected);
                              changeUser(selected);
                            }}
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label className="form-label">Date</label>
                          <input
                            type="date"
                            id="onDate"
                            name="onDate"
                            className="form-control"
                            onChange={(e) => {
                              setOnDate(e.target.value);
                            }}
                            required
                          />
                        </div>

                        <div className="form-group col-md-3 d-flex flex-column">
                          <label className="form-label" htmlFor="">
                            From Time
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              id="fromTime"
                              name="fromTime"
                              // className="form-control"
                              views={["hours", "minutes", "seconds"]}
                              onChange={(e) => {
                                const dateObj = new Date(e);
                                const time = dateObj.toLocaleTimeString("en", {
                                  hour12: false,
                                }); // 24-hour format
                                console.log(time);
                                setFromTime(time);
                              }}
                              ampm={false}
                              required
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="form-group col-md-3 d-flex flex-column">
                          <label className="form-label" htmlFor="">
                            To Time
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              views={["hours", "minutes", "seconds"]}
                              ampm={false}
                              id="toTime"
                              name="toTime"
                              // className="form-control"
                              onChange={(e) => {
                                const dateObj = new Date(e);
                                const time = dateObj.toLocaleTimeString("en", {
                                  hour12: false,
                                }); // 24-hour format
                                console.log(time);
                                setToTime(time);
                              }}
                              style={{ padding: "2px" }}
                              required
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="form-group col-md-3 mt-3">
                          <button type="submit" className="btn btn-danger ">
                            Delete Sessions
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handleDeleteSessionsBySession}>
                      <div className="row">
                        <div className="form-group col-md-3">
                          <label className="form-label">Sessions</label>
                          <Select
                            options={[
                              { value: "", label: "Select Session" },
                              ...sessions.map((session) => ({
                                value: session.name,
                                label: session.name,
                              })),
                            ]}
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={""}
                            isDisabled={false}
                            isLoading={sessions.length === 0 ? true : false}
                            isSearchable={true}
                            isRtl={false}
                            name="selectedSession"
                            onChange={(e) => {
                              const selected = e?.value;
                              console.log(selected);
                              changeSession(selected);
                              setSelectedSession(selected);
                            }}
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label className="form-label">Date</label>
                          <input
                            type="date"
                            id="onDate"
                            name="onDate"
                            className="form-control"
                            onChange={(e) => {
                              setOnDate(e.target.value);
                            }}
                            required
                          />
                        </div>

                        <div className="form-group col-md-3 d-flex flex-column">
                          <label className="form-label" htmlFor="">
                            From Time
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              id="fromTime"
                              name="fromTime"
                              // className="form-control"
                              views={["hours", "minutes", "seconds"]}
                              onChange={(e) => {
                                const dateObj = new Date(e);
                                const time = dateObj.toLocaleTimeString("en", {
                                  hour12: false,
                                }); // 24-hour format
                                console.log(time);
                                setFromTime(time);
                              }}
                              ampm={false}
                              required
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="form-group col-md-3 d-flex flex-column">
                          <label className="form-label" htmlFor="">
                            To Time
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              views={["hours", "minutes", "seconds"]}
                              ampm={false}
                              id="toTime"
                              name="toTime"
                              // className="form-control"
                              onChange={(e) => {
                                const dateObj = new Date(e);
                                const time = dateObj.toLocaleTimeString("en", {
                                  hour12: false,
                                }); // 24-hour format
                                console.log(time);
                                setToTime(time);
                              }}
                              style={{ padding: "2px" }}
                              required
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="form-group col-md-3 mt-3">
                          <button type="submit" className="btn btn-danger ">
                            Delete Sessions
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            ) : null}

            <div className="card">
              <div className="card-body">
                <div className="table">
                  <div className="col-md-12">
                    <table className="table table-bordered align-middle">
                      <tbody>
                        <tr>
                          <th>Client</th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <Select
                              options={[
                                { value: "", label: "Select" },
                                { value: "All", label: "All" },
                                ...users.map((user) => ({
                                  value: user.id,
                                  label: `${user.code} - ${user.name}`,
                                })),
                              ]}
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={""}
                              isDisabled={false}
                              isLoading={users.length === 0 ? true : false}
                              isSearchable={true}
                              isRtl={false}
                              name="selectedUser"
                              onChange={(e) => {
                                const selected = e?.value;
                                setSelectedUser(selected);
                                changeUser(selected);
                              }}
                            />
                          </td>
                          <td></td>
                          <td>Declare @ null</td>
                        </tr>
                        <tr>
                          <th>Session</th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <Select
                              options={[
                                { value: "", label: "Select Session" },
                                { value: "All", label: "All" },
                                ...sessions.map((session) => ({
                                  value: session.name,
                                  label: session.name,
                                })),
                              ]}
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={""}
                              isDisabled={false}
                              isLoading={sessions.length === 0 ? true : false}
                              isSearchable={true}
                              isRtl={false}
                              name="selectedSessionFilter"
                              onChange={(e) => {
                                const selected = e?.value;
                                changeSession(selected);
                              }}
                            />
                          </td>
                          <td>
                            <button className="btn btn-primary">View</button>
                          </td>

                          <td className="text-danger text-md text-bold">00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="col-md-12">
                    <table
                      className="table table-striped table-bordered"
                      style={{ padding: "0.75rem" }}
                    >
                      <thead>
                        <tr style={{ padding: "0.75rem" }}>
                          <th style={{ height: "67px", minWidth: "35px" }}>
                            #
                          </th>

                          <th>Code</th>
                          <th style={{ height: "67px", minWidth: "140px" }}>
                            Session
                          </th>

                          <th>Rate</th>
                          <th>Run</th>
                          <th>Mode</th>
                          <th>Amount</th>
                          <th>Dec</th>
                          <th style={{ height: "67px", minWidth: "150px" }}>
                            Created By
                          </th>
                          {accountType === "admin" && (
                            <th style={{ height: "67px", minWidth: "150px" }}>
                              Action
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData?.length > 0 ? (
                          sessionData.map((bets, index) => (
                            <tr key={index}>
                              <td style={{ padding: "0.75rem" }}>
                                {index + 1}
                              </td>

                              <td>
                                {bets.user_account.name}(
                                {bets.user_account.code})
                              </td>
                              <td
                                style={{ padding: "0.75rem", height: "70px" }}
                              >
                                {bets.session}{" "}
                                {!bets.view && (
                                  <span className="text-danger">(Hidden)</span>
                                )}
                              </td>

                              <td>{bets.rate}</td>
                              <td>{bets.runs}</td>
                              <td>{bets.mode}</td>
                              <td>{bets.amount}</td>
                              <td>{bets.declare}</td>

                              <td>{dateTimeFormatter(bets.createdAt)}</td>

                              {accountType === "admin" ? (
                                <td>
                                  <button
                                    className="btn btn-sm btn-primary btn-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteSession(bets.id);
                                      // trashSession(bets);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button>
                                  {isMasterAdmin === "1" ? (
                                    <button
                                      className="btn btn-sm btn-primary mx-2"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleSessionView(bets.id, bets.view);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </button>
                                  ) : null}
                                  {isMasterAdmin === "1" &&
                                  bets.declare === null ? (
                                    <button
                                      className="btn btn-sm btn-warning"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/inplay/session-bets/edit", {
                                          state: { data: bets },
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                  ) : null}
                                </td>
                              ) : null}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={accountType === "admin" ? 10 : 9}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
