import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";

// const BASE_URL = process.env.REACT_APP_API_URL;

async function mutationFn(gameId) {
  const axios = createProtectedAxios();
  const endpoint = `http://20.205.26.16/api/cricket/matches`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetMatchesList() {
  const { mutate, isLoading, data } = useMutation(
    ["get-matches-list"],
    (gameId) => mutationFn(gameId),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
