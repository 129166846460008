import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/PageHeader";
import useGetLiveMatch from "../../hooks/Games/useGetLiveMatch";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useGetMatchesList from "../../hooks/Games/Inplay/useGetMatchesList";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";

export default function InplayAddGame() {
  const navigate = useNavigate();
  const { data: inPlayGames } = useGetLiveMatch();

  const [data, setData] = useState([]);
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");

  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: getMatchesList } = useGetMatchesList();

  useEffect(() => {
    if (inPlayGames) {
      let matchData = inPlayGames?.data?.data;
      matchData = matchData.sort((a, b) => {
        return new Date(a.server) - new Date(b.server);
      });
      setData(matchData);
    }
  }, [inPlayGames]);

  const handleGetMatchesList = () => {
    getMatchesList({
      onSuccess: (response) => {
        console.log(response);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  const handleAddGame = () => {
    navigate("/inplay/add-game");
  };

  useEffect(() => {
    if (accountType === "admin" || accountType === "power_user") {
      const payload = {
        user_id: uuid,
        acc_type: accountType,
      };

      accountStatus(payload, {
        onSuccess: (response) => {
          let userData = response.data.data;

          if (
            userData.status === false ||
            userData.status === "false" ||
            userData.status === "0" ||
            userData.status === 0
          ) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else if (userData.password !== userPassword) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    } else {
      navigate("/inplay");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <PageHeader title="Inplay Add Match API" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title">Match Details</h3>
                  {accountType === "admin" || accountType === "power_user" ? (
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "16px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handleGetMatchesList();
                        }}
                      >
                        <FontAwesomeIcon icon={faRefresh} /> Refresh Matchlist
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ padding: "0.75rem" }}>S. No.</th>
                      <th style={{ padding: "0.75rem" }}>Code</th>
                      <th style={{ padding: "0.75rem" }}>Name</th>
                      <th style={{ padding: "0.75rem" }}>Date Time</th>
                      <th style={{ padding: "0.75rem" }}>Match Type</th>
                      <th style={{ padding: "0.75rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length !== 0 ? (
                      <>
                        {data?.map((item, index) => (
                          <tr key={index} id={`row${item.code}`}>
                            <td style={{ padding: "0.75rem" }}>{index + 1}</td>
                            <td style={{ padding: "0.75rem" }}>{item.code}</td>
                            <td style={{ padding: "0.75rem" }}>{item.name}</td>
                            <td style={{ padding: "0.75rem" }}>
                              {dateTimeFormatter(item.server)}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {item.match_type}
                            </td>
                            <td style={{ padding: "0.75rem" }}>
                              {accountType === "admin" ||
                              accountType === "power_user" ? (
                                <div className="d-flex gap-2 align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleAddGame();
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlus} /> Add Match
                                  </button>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center p-5">
                          <h6 className="p-5">No records to display</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
